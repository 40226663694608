import SiteLayout from "../layout/SiteLayout";
import Blog from "../pages/site/Blog";
import BeneficiosDoParcelamento from "../pages/site/Blog/Posts/benefícios-do-parcelamento-de-contas";
import EndividamentoImpactaPsicologico from "../pages/site/Blog/Posts/endividamento-impacto-psicologico";
import ParcelarMeusBoletos from "../pages/site/Blog/Posts/parcelamento-boletos";

const BlogRoutes = {
  path: "/blog",
  element: <SiteLayout />,
  children: [
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "como-parcelar-meus-boletos",
      element: <ParcelarMeusBoletos />,
    },
    {
      path: "endividamento-impacta-psicologico",
      element: <EndividamentoImpactaPsicologico />,
    },
    {
      path: "os-beneficios-do-parcelamento-de-contas",
      element: <BeneficiosDoParcelamento />,
    },
  ],
};

export default BlogRoutes;
