import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./index.scss";
import Rodape from "../rodape";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";
import { direcionaParaApp } from "@/services/utils";

const Ajuda = () => {
  const abreSimulador = () => {
    const metaData = JSON.parse(sessionStorage.getItem("metaData") || "{}");

    direcionaParaApp(
      `/app/simulacao?utm_source=${metaData.utm_source || ""}&utm_medium=${
        metaData.utm_medium || ""
      }&utm_campaign=${metaData.utm_campaign || ""}&utm_id=${
        metaData.utm_id || ""
      }&utm_term=${metaData.utm_term || ""}`
    );
  };

  return (
    <>
      <Helmet>
        <title>Como funciona</title>
        <link
          rel="canonical"
          href="https://quitaboletos.com.br/como-funciona"
        />
        <meta
          name="description"
          content="Parcele PIX ou boleto em até 12x no cartão de crédito"
        />
      </Helmet>
      <Container className="sessao-site sessao-2">
        <Grid
          container
          spacing={3}
          sx={{ p: 3 }}
          justifyContent="center"
          className="informacao-dados"
          onClick={() => {
            abreSimulador();
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <div className="item-como-funciona">
              <Typography className="subtitulo">
                <span className="titulo-numero">1</span>Simule
              </Typography>
              <Typography className="descricao">
                Clique em simular, informe seus dados e o valor da simulação.
              </Typography>
              <Typography className="descricao">
                Depois selecione qual a forma de pagamento que deseja realizar
                com seu cartão de crédito.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="item-como-funciona">
              <Typography className="subtitulo">
                <span className="titulo-numero">2</span>Informe seus dados
              </Typography>
              <Typography className="descricao">
                Para prosseguir a contratação precisamos de alguns dados
                pessoais como nome completo, CPF, endereço, etc.
              </Typography>
              <Typography className="descricao">
                Após informar os dados avance para o pagamento.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="item-como-funciona">
              <Typography className="subtitulo">
                <span className="titulo-numero">3</span>Pagamento
              </Typography>
              <Typography className="descricao">
                Pronto, agora é só informar os dados do seu cartão de crédito e
                confirmar a contratação!
              </Typography>
              <Typography className="descricao">Fácil né?!</Typography>
            </div>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              className="botao botao-simular"
              size="large"
              onClick={() => {
                abreSimulador();
              }}
            >
              CLIQUE AQUI PARA COMEÇAR
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-ajuda">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} align="center">
            <Typography variant="h3" className="titulo">
              Confira abaixo as dúvidas mais frequentes
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ m: { md: "auto" } }}
          maxWidth="md"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography className="subtitulo">
              Que tipos de contas podem ser parceladas?
            </Typography>

            <Typography className="descricao">
              Boletos que possuem código de barras: conta de luz, água, aluguel,
              condomínio, prestação de carro, impostos, mensalidade de cursos,
              plano de saúde etc...
            </Typography>

            <Typography className="subtitulo">
              Demora para meus boletos serem quitados?
            </Typography>

            <Typography className="descricao">
              Nosso prazo é de até 3 dias úteis para finalizar o seu pagamento.
              Porém, em alguns casos a operação é bem mais rápida podendo ser
              finalizada no mesmo dia.
            </Typography>

            <Typography className="subtitulo">
              O parcelamento pode ser feito de outra forma além do cartão de
              crédito?
            </Typography>

            <Typography className="descricao">
              Não, o parcelamento é feito apenas no cartão de crédito com limite
              disponível.
            </Typography>

            <Typography className="subtitulo">
              Posso utilizar mais de um cartão de crédito?
            </Typography>

            <Typography className="descricao">
              Sim, para facilitar sua vida, você pode usar mais de um cartão de
              crédito, desde que realizada uma operação por cartão até atingir o
              valor do seu boleto.
            </Typography>

            <Typography className="subtitulo">
              É possível utilizar um cartão de crédito em nome de outra pessoa?
            </Typography>

            <Typography className="descricao">
              Sim, você pode utilizar o cartão de outra pessoa para realizar a
              operação.
            </Typography>
            <Typography className="subtitulo">
              Precisa pagar algo para fazer uma simulação?
            </Typography>

            <Typography className="descricao">
              Não, a simulação em nosso site é gratuita, automatizada e segura!
              Faça a sua e receba o resultado na hora.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className="contato"
          style={{ marginTop: "3rem", cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=5555999042233&text=Ol%C3%A1. Preciso de ajuda com o portal!"
            );
          }}
        >
          <Grid item xs={12}>
            <Typography align="center" className="contato-texto">
              Ficou com dúvidas?
            </Typography>{" "}
            <Typography align="center" className="contato-texto">
              Entre em contato conosco:
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <img src="/whatsapp.png" alt=""></img>
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
};

export default Ajuda;
