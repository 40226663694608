import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { direcionaParaApp } from "@/services/utils";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "./Header.scss";

function Header() {
  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const abrirAction = (page) => {
    handleCloseNavMenu();
    navigation(page);
  };

  const abreSimulador = () => {
    const metaData = JSON.parse(sessionStorage.getItem("metaData") || "{}");

    direcionaParaApp(
      `/app/simulacao?utm_source=${metaData.utm_source || ""}&utm_medium=${
        metaData.utm_medium || ""
      }&utm_campaign=${metaData.utm_campaign || ""}&utm_id=${
        metaData.utm_id || ""
      }&utm_term=${metaData.utm_term || ""}`
    );
  };

  const entrar = () => {
    const metaData = JSON.parse(sessionStorage.getItem("metaData") || "{}");
    direcionaParaApp(
      `/app/login?utm_source=${metaData.utm_source || ""}&utm_medium=${
        metaData.utm_medium || ""
      }&utm_campaign=${metaData.utm_campaign || ""}&utm_id=${
        metaData.utm_id || ""
      }&utm_term=${metaData.utm_term || ""}`
    );
  };

  return (
    <AppBar
      className="toolbar"
      style={{
        position: "fixed",
        color: "white",
        elevation: 0,
        sx: {},
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              color="black"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  abrirAction("/");
                }}
              >
                <Typography textAlign="center">INÍCIO</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  abrirAction("/ajuda");
                }}
              >
                <Typography textAlign="center">COMO FUNCIONA</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  abrirAction("/parceiros");
                }}
              >
                <Typography textAlign="center">PARCEIROS</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  abrirAction("/blog");
                }}
              >
                <Typography textAlign="center">BLOG</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  entrar();
                }}
              >
                <Typography textAlign="center">ENTRAR</Typography>
              </MenuItem>
              <MenuItem onClick={abreSimulador}>
                <Typography textAlign="center">SIMULAR</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src="/site/logo_cabecalho.png"
              alt=""
              className="logo-quita"
              onClick={() => {
                window.location.href = "/#inicio";
              }}
            ></img>
          </Typography>
          <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
            <Grid item md={3}>
              <Box
                sx={{
                  pl: 1,
                }}
              >
                <img
                  src="/site/logo_cabecalho.png"
                  alt=""
                  className="logo-quita"
                  onClick={() => {
                    window.location.href = "/#inicio";
                  }}
                ></img>
              </Box>
            </Grid>
            <Grid item md={9}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  className="botao"
                  onClick={() => {
                    abrirAction("/");
                  }}
                  sx={{ my: 1, pl: 2, display: "block" }}
                >
                  INÍCIO
                </Button>
                <Button
                  className="botao"
                  onClick={() => {
                    abrirAction("/como-funciona");
                  }}
                  sx={{ my: 1, pl: 2, display: "block" }}
                >
                  COMO FUNCIONA
                </Button>
                <Button
                  className="botao"
                  onClick={() => {
                    abrirAction("/parceiros");
                  }}
                  sx={{ my: 1, pl: 2, display: "block" }}
                >
                  PARCEIROS
                </Button>
                <Button
                  className="botao"
                  onClick={() => {
                    abrirAction("/blog");
                  }}
                  sx={{ my: 1, pl: 2, display: "block" }}
                >
                  BLOG
                </Button>
                <Button
                  className="botao"
                  onClick={() => {
                    entrar();
                  }}
                  sx={{ my: 1, pl: 2, display: "block" }}
                >
                  ENTRAR
                </Button>

                <Button
                  variant="contained"
                  className="botao botao-simular"
                  onClick={abreSimulador}
                  sx={{ my: 1, ml: 4, mr: 2, display: "block" }}
                >
                  SIMULAR
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
