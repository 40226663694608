import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

import "./index.scss";

const initialValues = [
  {
    image: "/site/blog/blog-psicologico.jpg",
    titleImg: "O endividamento no Brasil e o impacto psicológico causado.",
    title: "O endividamento no Brasil e o impacto psicológico causado.",
    description:
      "O endividamento é uma realidade que afeta milhões de brasileiros, e os impactos psicológicos dessa situação podem ser profundos.",
    url: "endividamento-impacta-psicologico",
  },
  {
    image: "/site/blog/logo-os-benefícios-do-parcelamento-de-contas.jpg",
    titleImg: "Os benefícios do parcelamento de contas.",
    title: "Os benefícios do parcelamento de contas.",
    description:
      "O dilema cotidiano ainda é “devo parcelar minhas dívidas ou pagar à vista?”. Gaste apenas 5 minutos do seu tempo e descubra em  4 tópicos os benefícios de parcelar seus boletos.",
    url: "os-beneficios-do-parcelamento-de-contas",
  },
  {
    image: "/site/logo-como-parcelar-meus-boletos.png",
    titleImg: "Como parcelar meus boletos?",
    title: "Como parcelar meus boletos?",
    description:
      "Quando as contas começam a se acumular e o orçamento aperta, o que fazer? Neste post iremos mostrar as facilidades que o parcelamento dos seus boletos pode trazer para o seu orçamento!",
    url: "como-parcelar-meus-boletos",
  },
];

export default function CardPosts({ justThree, ...props }) {
  const navigation = useNavigate();
  const [posts, setPosts] = useState([]);
  const [isThreePosts, setIsThreePosts] = useState(false);

  useEffect(() => {
    if (isThreePosts) {
      setIsThreePosts(false);
      const lastPosts = initialValues.slice(0, 3);
      setPosts(lastPosts);
    } else if (!isThreePosts && posts.length === 0) {
      setPosts(initialValues);
    }
  }, [posts, isThreePosts]);

  useEffect(() => {
    setIsThreePosts(justThree);
  }, [justThree]);

  return (
    <>
      {posts.map((post) => {
        return (
          <Card key={post.url} {...props}>
            <CardMedia
              className="card-content"
              onClick={() => {
                navigation(justThree ? `/blog/${post.url}` : `${post.url}`);
              }}
              component="img"
              image={post.image}
              title={post.titleImg}
            />

            <CardContent
              className="card-content"
              onClick={() =>
                navigation(justThree ? `/blog/${post.url}` : `${post.url}`)
              }>
              <Typography sx={{ fontSize: 24 }}>{post.title}</Typography>
              <Typography variant="body2">{post.description}</Typography>
            </CardContent>
            {!justThree && (
              <CardActions sx={{ justifyContent: "end" }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    navigation(justThree ? `/blog/${post.url}` : `${post.url}`)
                  }>
                  Continuar lendo
                  {process.env.REACT_APP_ENVIRONMENT === "HOMOLOG" ? "!" : "."}
                </Button>
              </CardActions>
            )}
          </Card>
        );
      })}
    </>
  );
}
