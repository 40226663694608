import { Helmet } from "react-helmet";
import Grid from "@mui/material/Grid";

import Rodape from "../rodape";
import CardPosts from "./CardPosts";
import "./index.scss";
import { isMobile } from "react-device-detect";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
export default function Blog() {
  return (
    <>
      <Helmet>
        <title>Blog</title>
        <link rel="canonical" href="https://quitaboletos.com.br/blog" />
        <meta
          name="description"
          content="Confira nossas postagens e receba dicas incríveis para organizar seu orçamento."
        />
      </Helmet>
      <Container className="sessao-site pagina-blog">
        <Grid container spacing={3} sx={{ p: 3 }} justifyContent="center">
          <Grid xs={12} align="center">
            <Typography className="titulo" variant="h3" sx={{ p: 3, mt: 3 }}>
              Confira nosso <span style={{ color: "#179b46" }}>Blog</span>
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sx={{
              display: "grid",
              justifyItems: "center",
            }}>
            <CardPosts
              sx={{
                margin: "1rem",
                marginBottom: "2rem",
                width: isMobile ? "auto" : 600,
              }}
              {...{
                justThree: false,
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Rodape></Rodape>
    </>
  );
}
