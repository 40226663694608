import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Rodape() {
  const navigation = useNavigate();
  useEffect(() => {
    const reclameAquiDiv = document.getElementById("ra-verified-seal");
    const script = document.createElement("script");

    script.setAttribute(
      "src",
      "https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js"
    );
    reclameAquiDiv.appendChild(script);
    reclameAquiDiv.removeChild(script);
  }, []);

  return (
    <Container className="sessao-site sessao-rodape">
      <Grid container sx={{ p: 3 }} justifyContent="center">
        <Grid item xs={12} sm={3}>
          <Typography className="imagem">
            <img src="/site/logo_rodape.png" alt=""></img>
          </Typography>
          <Typography className="contato">(55) 99904 2233</Typography>
          <Typography className="contato">
            contato@quitaboletos.com.br
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            textAlign: { xs: "left", sm: "right" },
          }}
        >
          <Typography className="redes-sociais">
            <WhatsAppIcon
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send?phone=5555999042233&text=Ol%C3%A1",
                  "_blank"
                );
              }}
            ></WhatsAppIcon>
            <InstagramIcon
              onClick={() => {
                window.open(
                  "https://www.instagram.com/quitaboletos/",
                  "_blank"
                );
              }}
            ></InstagramIcon>
            <FacebookIcon
              onClick={() => {
                window.open("https://www.facebook.com/QuitaBoletos/", "_blank");
              }}
            ></FacebookIcon>
            <YouTubeIcon
              onClick={() => {
                window.open(
                  "https://www.youtube.com/channel/UCMDBfomX_bSscVXL9Waj4dw",
                  "_blank"
                );
              }}
            ></YouTubeIcon>
            <LinkedInIcon
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/quita-boletos/",
                  "_blank"
                );
              }}
            ></LinkedInIcon>
          </Typography>
          <Typography className="endereco">CNPJ: 42.426.994/0001-52</Typography>
          <Typography className="endereco">Rua Santa Rosa, 167</Typography>
          <Typography className="endereco">
            Horizontina RS | Brasil CEP 98920-000
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <div id="ra-verified-seal" style={{ borderRadius: "10px" }}>
          <script
            type="text/javascript"
            style={{ backgroundColor: "red" }}
            id="ra-embed-verified-seal"
            src="https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js"
            data-id="RmNnY2lQZHFSLVo0b0RNcTpxdWl0YS1ib2xldG9z"
            data-target="ra-verified-seal"
            data-model="2"
          ></script>
        </div>
      </Grid>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          className="termos-uso"
          justifyContent="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            m: 2,
          }}
        >
          <Typography
            onClick={() => {
              navigation("/termos-de-uso");
            }}
          >
            Termos de uso
          </Typography>
          <Typography className="barra">|</Typography>
          <Typography
            onClick={() => {
              window.open("/politica-privacidade.html", "_blank");
            }}
          >
            Política de privacidade
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Rodape;
