import { ehHomologacao, ehLocal } from "@/services/api";

export function formatMonetario(valor) {
  valor = valor || 0;
  if (typeof valor == "string") {
    valor = parseFloat(valor);
  }
  return valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
}
export function apenasNumeros(str) {
  return (str || "").toString().replace(/\D/g, "");
}
export function apenasLetrasNumeros(str) {
  return (str || "").toString().replace(/[^a-zA-Z0-9]/g, "");
}
export function apenasMonetario(str) {
  return (str || "").toString().replace(/[^01234567890,.]/g, "");
}
export function retornaUsuarioLogado() {
  return JSON.parse(sessionStorage.getItem("dadosUsuarioLogado"));
}

export function ehProducao() {
  return process.env.NODE_ENV === "production";
}

export function retornaUrlApp() {
  if (ehLocal()) {
    return "http://localhost:3001/";
  }
  if (ehHomologacao()) {
    return "https://app.homologacao.quitaboletos.com/";
  }
  return "https://quitaboletos.com/";
}

export function resetCaptcha(captchaRef) {
  if (captchaRef && captchaRef.current) {
    captchaRef.current.reset();
  }
}

export function retornaTokenCaptcha(captchaRef) {
  if (!captchaRef || !captchaRef.current) {
    return "";
  }
  if (!ehProducao() && !ehHomologacao()) {
    return "local";
  }
  const tokenCaptcha = captchaRef.current.getValue();
  resetCaptcha(captchaRef);
  return tokenCaptcha;
}

export function retornaUrlAdmin() {
  if (ehLocal()) {
    return "http://localhost:3002/";
  }
  if (ehHomologacao()) {
    return "https://admin.homologacao.quitaboletos.com/";
  }
  return "https://admin.quitaboletos.com/";
}

export function aoPressionarEnter(event, executar) {
  if (event.key === "Enter") {
    executar();
  }
}

export function direcionaParaApp(url) {
  if (ehHomologacao()) {
    window.location.href = "https://app.homologacao.quitaboletos.com" + url;
    return;
  }
  window.location.href = "https://app.quitaboletos.com.br" + url;
}
